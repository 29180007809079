import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import HomeOne from "./pages/HomeOne";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import WorkInfo from "./pages/WorkInfo";
import BlogDetails from "./pages/BlogDetails";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Article from "./pages/Article";
import Activity from "./pages/Actvity";
import About from "./pages/About";
import Volunteer from "./pages/Volunteer";
import NewsDetails from "./pages/NewsDetails";
import ActivityDetailsPage from "./pages/ActivityDetailsPage";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            exact
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/news-details/:id"}`}
            component={NewsDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/work-details/:companyname"}`}
            component={WorkDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/work-info/:companyname/:id"}`}
            component={WorkInfo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/activity-details/:id"}`}
            component={ActivityDetailsPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/news"}`}
            component={News}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/article"}`}
            component={Article}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/activity"}`}
            component={Activity}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/volunteer"}`}
            component={Volunteer}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/work"}`}
            component={Work}
          />
        </Switch>
      </NavScrollTop>
    </Router>
  );
}

export default App;
