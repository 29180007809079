import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";
const BlogClassic = ({ data }) => {
  return (
    <div className="blog-3 col">
      <div className="thumbnail">
        <Link
          to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
          className="image"
        >
          <img
            src={`${getData(data && data._embedded, "image")}`}
            alt="Blog Image"
          />
        </Link>
      </div>
      <div className="info">
        <ul className="meta">
          <li>
            <i className="fal fa-pencil-alt"></i>
            {`Нийтэлсэн ${data && data.acf.author}`}
          </li>
          <li>
            <i className="far fa-calendar"></i>
            {data && data.acf && data.acf.date}
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/article"}>
              <i className="fas fa-tags"></i>
              {data && data.acf.tag}
            </Link>
          </li>
        </ul>
        <h3 className="title">
          <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
            {data && data.title.rendered}
          </Link>
        </h3>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: data && data.excerpt.rendered }}
        ></div>
        <Link
          to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
          className="btn btn-primary btn-hover-secondary mt-6"
        >
          Дэлгэрэнгүй Унших
        </Link>
      </div>
    </div>
  );
};
BlogClassic.propTypes = {
  data: PropTypes.object,
};
export default BlogClassic;
