import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import WorkCompanyList from "../../components/Work/WorkCompanyList.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from "parallax-js";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import SidebarCategoriesNews from "../../components/Sidebar/SidebarCategoriesNews.jsx";
import SidebarCategoriesActivity from "../../components/Sidebar/SidebarCategoriesActivity";
import SidebarPostActivity from "../../components/Sidebar/SidebarPostActivity";
import Pagination from "../../components/pagination/Pagination.jsx";

import { DataContext } from "../../context.js";
const WorkPage = ({ classOption }) => {
  const sceneEl = useRef(null);
  const value = useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState([10]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div
      className={`section section-padding-t90 section-padding-bottom ${classOption}`}
    >
      <div className="container">
        <SectionTitle
          headingOption="fz-32"
          title="Хөгжлийн бэрхшээлтэй иргэн ажиллах боломжтой ажлын байр"
        />
        <div className="row mb-n10">
          <div className="col-lg-8 col-12 order-lg-1 mb-10">
            <div className="row row-cols-md-2 row-cols-1 mb-n6 icon-box-shape-animation no-gutters">
              {value.companyList
                .slice(indexOfFirstPost, indexOfLastPost)
                .map((companies, key) => {
                  return (
                    <div key={key} className="col mb-6" data-aos="fade-up">
                      <WorkCompanyList data={companies} key={key} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-lg-4 col-lg-4 col-12 order-lg-1 mb-10">
            <SidebarWrap>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Төрөлүүд"
                />
                <SidebarCategories />
                <SidebarCategoriesNews />
                <SidebarCategoriesActivity />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Бусад арга хэмжээ"
                />
                <SidebarPostActivity />
              </SidebarWidget>
            </SidebarWrap>
          </div>
          <div className="col-12  order-lg-2"></div>
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={value.companyList.length}
          paginate={paginate}
        />
        <div className="shape shape-1" id="scene" ref={sceneEl}>
          <span data-depth="1">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/shape-animation/video-shape-1.png"
              }
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
  );
};

WorkPage.propTypes = {
  classOption: PropTypes.string,
};
WorkPage.defaultProps = {
  classOption: "section section-padding-t90 section-padding-bottom",
};

export default WorkPage;
