import React, { useContext } from "react";
import { slugify, containsObject } from "../../utils";
import { Link } from "react-router-dom";
import { DataContext } from "../../context";

const SidebarCategories = () => {
  const value = useContext(DataContext);
  const cats = value.article.map((item) => {
    return item.acf.tag;
  });
  let categories = [];
  cats.forEach((cat) => {
    const obj = {
      title: cat.trim(),
      slug: slugify(cat),
      count: 1,
    };

    const objIndex = containsObject(obj, categories);
    if (objIndex !== -1) {
      const prevCount = categories[objIndex].count;
      categories[objIndex] = {
        title: cat.trim(),
        slug: slugify(cat),
        count: prevCount + 1,
      };
    } else {
      categories.push(obj);
    }
  });

  return (
    <div className="sidebar-widget-content">
      <ul className="sidebar-widget-cate-list">
        {categories.map((cat) => {
          return (
            <li key={cat.slug}>
              <Link to={process.env.PUBLIC_URL + "/article"}>
                <span className="text">{cat.title}</span>
                <span className="count">{cat.count}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarCategories;
