import React from "react";
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from ".";

const AccordionWrap = () => {
  return (
    <div className="agency-accordion col-lg-7 max-mb-n30">
      <Accordion>
        <AccordionItem id="one">
          <AccordionTitle id="one">ХӨТӨЛБӨРИЙН ТАЛААР</AccordionTitle>
          <AccordionContent id="one">
            Бид чадна-Сайн дурын хөтөлбөр нь их дээд сургуулийн оюутнуудад
            зориулсан 4 сарын хугацаанд үргэлжилдэг хөтөлбөр юм. Хөтөлбөрт
            шалгарсан залуус бэлтгэсэн хөтөлбөрийн дагуу хувь хүний болон
            манлайллын чадвараа дээшлүүлдэг ба хөгжлийн бэрхшээлтэй иргэдэд
            чиглэгдсэн сайн дурын ажил зохион байгуулах, мэдээ мэдээлэл, нийтлэл
            бичих зэрэг бусад ажлуудыг багаараа хийж гүйцэтгэдэг. Хөгжлийн
            бэрхшээлтэй иргэдэд олон зүйлс тулгамдаж байгааг мэдэрсэн залуус
            сайн дурын ажил хийж тэдэнд туслахыг хүсдэг хэдий ч хаана хандахаа
            мэдэхгүй, мөн тэдний цаашид хөгжин дэвшихэд нь туслалцаа үзүүлэх
            зөвлөгч, манлайлагчидтай танилцах боломж төдийлөн байдаггүй байна.
            Эдгээр дүгнэлтээс үндэслэн Бид чадна-Сайн дурын хөтөлбөрийг анх
            санаачилсан бөгөөд амжилттай хэрэгжиж байна
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="two">
          <AccordionTitle id="two">ХЭН ХАМРАГДАХ БОЛОМЖТОЙ ВЭ?</AccordionTitle>
          <AccordionContent id="two">
            Хөтөлбөрт оролцогч нь англи хэлний өндөр мэдлэгтэй, их дээд
            сургуулийн оюутан байх ба хөгжлийн бэрхшээлтэй иргэдэд ээлтэй
            нийгмийн загварыг бүтээхэд хувь нэмрээ оруулах эрмэлзэлтэй байх
            шаардлагатай.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="three">
          <AccordionTitle id="three">ХӨТӨЛБӨРИЙН АГУУЛГА</AccordionTitle>
          <AccordionContent id="three">
            Хөтөлбөрийн нээлт: Оролцогчид үр дүнтэй манлайлал болон хөтөлбөрийн
            зохион байгуулалтын үндсэн ойлголт авч, өөр хоорондоо танилцан,
            холбоо харилцаа үүсгэх эхний алхам болно. Сургалтууд: Хөтөлбөрийн
            турш АНУ-ын Засгийн Газрын тэтгэлгээр суралцаж ирсэн болон манай
            улсын хүндрэлтэй асуудлуудыг шийдэхэд хувь нэмрээ оруулж яваа
            манлайлагчдын яриаг сонсож, мэдлэгээ бататгана. Мөн төсөл
            хэрэгжүүлэх, багийн менежмент, цагийн менежмэнт, бүтээлч сэтгэлгээ
            зэрэг олон чиглэлээр ур чадвараа бататгах сургалтуудад хамрагдана
            Идэвхтэй үйл ажиллагаа: Оролцогчидын төрөл бүрийн чадавхийг ахиулах
            зорилгоор илтгэлийн уралдаан, мэтгэлцээн, номын хэлэлцүүлэг зохион
            байгуулна. Аялал, чөлөөт цаг: Оролцогчид бие биетэйгээ илүү
            танилцах, сайн дурын ажлын сэдэвээ хамтарч ярилцаж гаргахад хамтдаа
            цагийг өнгөрөөх боломжийг бий болгох үүднээс байгалийн аялал, спорт
            өдөр зэргийг зохион байгуулна. Нийгэмд чиглэсэн төсөл: Манлайллын
            хөтөлбөрийн оролцогчид багт хуваагдан хөгжлийн бэрхшээлтэй иргэдэд
            чиглэсэн төслийн санаа гаргаж, хэрэгжүүлнэ. Хаалт, төгсөлтийн арга
            хэмжээ: Хаалтын сургалт семинар хийж, төгсөлтийн үйл ажиллагааны
            үеэр төгсөгчид туршлага, сургамжаа тунгаан ярилцаж, тусгаж авна.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionWrap;
