import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import WorkDetailsContainer from "../container/Work/WorkDetailsContainer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { DataContext } from "../context";

const WorkDetails = ({
  match: {
    params: { companyname },
  },
}) => {
  const workName = companyname;
  const value = useContext(DataContext);
  const [companyData, setCompanyData] = useState([]);
  const data = value.companyList.filter((work) => {
    return work.acf.companyname === workName;
  });

  useEffect(() => {
    if (workName === "it-wizard") return setCompanyData(value.companyItWizard);
    if (workName === "sansar") return setCompanyData(value.companySansar);
    if (workName === "eco-global")
      return setCompanyData(value.companyEcoGlobal);
    if (workName === "mogul-mineral")
      return setCompanyData(value.companyMogulMineral);
    if (workName === "stimo") return setCompanyData(value.companyStimo);
    if (workName === "goyol-cashmere")
      return setCompanyData(value.companyGoyolCashmere);
    if (workName === "kapitron-bank")
      return setCompanyData(value.companyKapitronBank);
    if (workName === "kunz") return setCompanyData(value.companyKunz);
    if (workName === "minii-delguur")
      return setCompanyData(value.companyMiniiDelguur);
    if (workName === "tavan-bogd")
      return setCompanyData(value.companyTavanBogd);
    if (workName === "yoshinoya") return setCompanyData(value.companyYohinoya);
    if (workName === "asteriks") return setCompanyData(value.companyAsteriks);
    if (workName === "monkon") return setCompanyData(value.companyMonkon);
    if (workName === "kdi") return setCompanyData(value.companyKdi);
  }, []);

  const filteredWorks = companyData.filter((val) => {
    return value.companyList.find((a) => {
      return val.acf.companyname === a.acf.companyname;
    });
  });

  console.log(filteredWorks[0]?.acf.companyname);
  return (
    <React.Fragment>
      <SEO title="Бид чадна || Ажлын байр" />
      <Header />
      <Breadcrumb
        image={`images/work/detail-image/${filteredWorks[0]?.acf.companyname}.jpeg`}
        title={data[0]?.title.rendered}
        content="Home"
        contentTwo="Ажлын мэдээлэл"
        url={"work"}
      />
      <WorkDetailsContainer data={data[0]} listData={filteredWorks} />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

WorkDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default WorkDetails;
