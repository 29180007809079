import React, { useState } from "react";
import PropTypes from "prop-types";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import SidebarCategoriesNews from "../../components/Sidebar/SidebarCategoriesNews.jsx";
import SidebarCategoriesActivity from "../../components/Sidebar/SidebarCategoriesActivity";
import SidebarPostActivity from "../../components/Sidebar/SidebarPostActivity";
import { getData } from "../../utils/getData.js";

const WorkInfoContainer = ({ data, listData }) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="section section-padding">
      <div className="container ">
        <div className="row row-col-4">
          <div className="container col-12 col-md-7 mb-10 border">
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                Ажлын дэлгэнэгүй
              </button>

              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                Бидний тухай
              </button>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 1 ? "content  active-content" : "content"
                }
              >
                <div className="desc">
                  <div className="company-image " data-aos="fade-up">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `${getData(data && data._embedded, "image")}`
                      }
                      alt="Company Image"
                    />
                  </div>
                  <div className="info">
                    <h3 className="title my-5 text-center text-primary">
                      {data && data.title.rendered}
                    </h3>
                    <div className=" p-5">
                      <div className="info">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data && data.content.rendered,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 2 ? "content  active-content" : "content"
                }
              >
                <div className="company-image " data-aos="fade-up">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `${getData(data && data._embedded, "image")}`
                    }
                    alt="Company Image"
                  />
                </div>
                <h3 className="title my-5 text-center text-primary">
                  Бидний тухай
                </h3>
                <hr />{" "}
                <div className="info">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: listData && listData.content.rendered,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-4 mb-10 mx-5">
            <SidebarWrap>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Төрөлүүд"
                />
                <SidebarCategories />
                <SidebarCategoriesNews />
                <SidebarCategoriesActivity />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Бусад арга хэмжээ"
                />
                <SidebarPostActivity />
              </SidebarWidget>
            </SidebarWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

WorkInfoContainer.propTypes = {
  data: PropTypes.object,
  listData: PropTypes.object,
};

export default WorkInfoContainer;
