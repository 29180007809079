import React from "react";
// import { slugify } from "../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const NewsDetails = ({ data }) => {
  // const cate = data.categories.map((value, i) => {
  //   return (
  //     <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>
  //       {value}
  //       {i !== data.categories.length - 1 && ","}
  //     </Link>
  //   );
  // });
  return (
    <div className="blog-3 blog-details col" data-aos="fade-up">
      <div className="thumbnail">
        <img
          className="w-100"
          src={
            process.env.PUBLIC_URL +
            `${getData(data && data._embedded, "image")}`
          }
          alt="News Image"
        />
      </div>
      <div className="info">
        <h3 className="title">{data && data.title.rendered}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: data && data.content.rendered }}
        />
        <ul className="meta mb-0 mt-12">
          <li>
            <i className="fal fa-pencil-alt"></i>
            {`Нийтэлсэн ${data && data.acf.author}`}
          </li>
          <li>
            <i className="far fa-calendar"></i>
            {data && data.acf.date}
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/news"}>
              <i className="fas fa-tags"></i>
              {data && data.acf.tag}
            </Link>
          </li>
          <li className="media">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <i className="fas fa-share-alt"></i>Share
            </Link>
            <div className="list">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

NewsDetails.propTypes = {
  data: PropTypes.object,
};

export default NewsDetails;
