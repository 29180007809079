import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context";

const SidebarPost = () => {
  const value = useContext(DataContext);
  return (
    <div className="sidebar-widget-content">
      <ul className="sidebar-widget-list-post">
        {value.article.slice(0, 4).map((value) => {
          return (
            <li key={value.id}>
              <span className="cate">Нийтлэл</span>
              <Link to={process.env.PUBLIC_URL + `/blog-details/${value.id}`}>
                {value.title.rendered}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarPost;
