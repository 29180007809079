import React, { Component } from "react";
import WPAPI from "wpapi";
import axios from "axios";
import Config from "./config";
import PropTypes from "prop-types";

const wp = new WPAPI({ endpoint: Config.apiUrl });

const DataContext = React.createContext();

class DataProvider extends Component {
  state = {
    news: [],
    article: [],
    activity: [],
    companyList: [],
    companySansar: [],
    companyEcoGlobal: [],
    companyItWizard: [],
    companyMogulMineral: [],
    companyStimo: [],
    companyBsb: [],
    companyGoyolCashmere: [],
    companyKapitronBank: [],
    companyKunz: [],
    companyMiniiDelguur: [],
    companyTavanBogd: [],
    companyYohinoya: [],
    companyAsteriks: [],
    companyMonkon: [],
    companyKdi: [],
  };

  componentDidMount() {
    const isMounted = true;
    const newsFeed = wp.posts().categories(2).perPage(100).embed();
    const articleFeed = wp.posts().categories(5).perPage(100).embed();
    const activityFeed = wp.posts().categories(23).perPage(100).embed();
    const companyList = wp.posts().categories(26).perPage(100).embed();
    const workFeedSansar = wp.posts().categories(25).perPage(100).embed();
    const workFeedItWizard = wp.posts().categories(28).perPage(100).embed();
    const workFeedMogulMineral = wp.posts().categories(35).perPage(100).embed();
    const workFeedStimo = wp.posts().categories(29).perPage(100).embed();
    const workFeedBsb = wp.posts().categories(33).perPage(100).embed();
    const workFeedGoyolCashmere = wp
      .posts()
      .categories(34)
      .perPage(100)
      .embed();
    const workFeedKapitronBank = wp.posts().categories(36).perPage(100).embed();
    const workFeedKunz = wp.posts().categories(32).perPage(100).embed();
    const workFeedKdi = wp.posts().categories(41).perPage(100).embed();
    const workFeedMiniiDelguur = wp.posts().categories(31).perPage(100).embed();
    const workFeedTavanBogd = wp.posts().categories(30).perPage(100).embed();
    const workFeedYohinoya = wp.posts().categories(27).perPage(100).embed();
    const workFeedAsteriks = wp.posts().categories(53).perPage(100).embed();
    const workFeedMonkon = wp.posts().categories(48).perPage(100).embed();
    const workFeedEcoGlobal = wp.posts().categories(40).perPage(100).embed();

    axios.get(newsFeed).then((res) => {
      if (isMounted) {
        this.setState({
          news: res.data,
        });
      }
    });
    axios.get(articleFeed).then((res) => {
      if (isMounted) {
        this.setState({
          article: res.data,
        });
      }
    });
    axios.get(activityFeed).then((res) => {
      if (isMounted) {
        this.setState({
          activity: res.data,
        });
      }
    });
    axios.get(workFeedSansar).then((res) => {
      if (isMounted) {
        this.setState({
          companySansar: res.data,
        });
      }
    });
    axios.get(companyList).then((res) => {
      if (isMounted) {
        this.setState({
          companyList: res.data,
        });
      }
    });
    axios.get(workFeedItWizard).then((res) => {
      if (isMounted) {
        this.setState({
          companyItWizard: res.data,
        });
      }
    });
    axios.get(workFeedMogulMineral).then((res) => {
      if (isMounted) {
        this.setState({
          companyMogulMineral: res.data,
        });
      }
    });
    axios.get(workFeedStimo).then((res) => {
      if (isMounted) {
        this.setState({
          companyStimo: res.data,
        });
      }
    });
    axios.get(workFeedBsb).then((res) => {
      if (isMounted) {
        this.setState({
          companyBsb: res.data,
        });
      }
    });
    axios.get(workFeedGoyolCashmere).then((res) => {
      if (isMounted) {
        this.setState({
          companyGoyolCashmere: res.data,
        });
      }
    });
    axios.get(workFeedKapitronBank).then((res) => {
      if (isMounted) {
        this.setState({
          companyKapitronBank: res.data,
        });
      }
    });
    axios.get(workFeedKunz).then((res) => {
      if (isMounted) {
        this.setState({
          companyKunz: res.data,
        });
      }
    });
    axios.get(workFeedMiniiDelguur).then((res) => {
      if (isMounted) {
        this.setState({
          companyMiniiDelguur: res.data,
        });
      }
    });
    axios.get(workFeedKunz).then((res) => {
      if (isMounted) {
        this.setState({
          companyKunz: res.data,
        });
      }
    });
    axios.get(workFeedTavanBogd).then((res) => {
      if (isMounted) {
        this.setState({
          companyTavanBogd: res.data,
        });
      }
    });
    axios.get(workFeedYohinoya).then((res) => {
      if (isMounted) {
        this.setState({
          companyYohinoya: res.data,
        });
      }
    });
    axios.get(workFeedAsteriks).then((res) => {
      if (isMounted) {
        this.setState({
          companyAsteriks: res.data,
        });
      }
    });
    axios.get(workFeedMonkon).then((res) => {
      if (isMounted) {
        this.setState({
          companyMonkon: res.data,
        });
      }
    });
    axios.get(workFeedKdi).then((res) => {
      if (isMounted) {
        this.setState({
          companyKdi: res.data,
        });
      }
    });
    axios.get(workFeedEcoGlobal).then((res) => {
      if (isMounted) {
        this.setState({
          companyEcoGlobal: res.data,
        });
      }
    });
  }

  render() {
    return (
      <DataContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

const DataConsumer = DataContext.Consumer;

DataProvider.propTypes = {
  children: PropTypes.any,
};

export { DataProvider, DataConsumer, DataContext };
