import React from "react";
import { useState, useContext } from "react";
import NewsItem from "../../components/NewsItem/NewsItem";
import Pagination from "../../components/pagination/Pagination.jsx";
import { DataContext } from "../../context";

const PageNews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState([9]);
  const value = useContext(DataContext);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  console.log(value.news);
  return (
    <div className="section section-padding fix">
      <div className="container">
        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
          {value &&
            value.news
              .slice(indexOfFirstPost, indexOfLastPost)
              .map((single) => {
                return (
                  <div key={single.id} className="col mb-6" data-aos="fade-up">
                    <NewsItem data={single} />
                  </div>
                );
              })}
          ;
        </div>
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={value.news.length}
        paginate={paginate}
      />
    </div>
  );
};

export default PageNews;
