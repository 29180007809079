import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const IconBox = ({ data, classOption }) => {
  return (
    <div className={`row icon-box border border-light shadow ${classOption}`}>
      <div className="content d-flex align-items-center justify-content-between col-12 p-0">
        <div className="text col-12 col-sm-9 col-md-9 col-lg-9">
          <Link
            to={process.env.PUBLIC_URL + `/activity-details/${data.id}`}
            className="title"
          >
            <h3 className="title  text-primary">
              {data && data.title.rendered}
            </h3>
          </Link>
          <div className="info">
            <ul className="meta list-unstyled">
              <li>
                <i className="far fa-map-marker-alt">
                  {" "}
                  {data && data.acf.location}
                </i>
              </li>
            </ul>
          </div>
          <Link
            to={process.env.PUBLIC_URL + `/activity-details/${data.id}`}
            className="subtitle"
          >
            <div
              className="desc mb-2"
              dangerouslySetInnerHTML={{
                __html: data && data.excerpt.rendered,
              }}
            ></div>
          </Link>
        </div>
        <Link
          to={process.env.PUBLIC_URL + `/activity-details/${data.id}`}
          className="image d-none d-sm-inline mg-responsive col-xl-3 col-lg-3 col-md-3 col-sm-4 ml-5 "
        >
          <img
            className=" rounded-circle  shadow "
            src={
              process.env.PUBLIC_URL + getData(data && data._embedded, "image")
            }
            alt="News Image"
          />
        </Link>
      </div>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

IconBox.defaultProps = {
  classOption: "icon-box ",
};

export default IconBox;
