import PropTypes from "prop-types";
import React from "react";
import { useState, useContext } from "react";
import BlogClassic from "../../components/Blog/BlogClassic.jsx";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import SidebarCategoriesNews from "../../components/Sidebar/SidebarCategoriesNews.jsx";
import SidebarCategoriesActivity from "../../components/Sidebar/SidebarCategoriesActivity.jsx";
import SidebarPost from "../../components/Sidebar/SidebarPost.jsx";
import Pagination from "../../components/pagination/Pagination.jsx";
import { DataContext } from "../../context";

const BlogClassicContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState([9]);
  const value = useContext(DataContext);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="section section-padding fix">
      <div className="container">
        <div className="row mb-n10">
          <div className="col-lg-8 col-11 order-lg-1 mb-10">
            <div className="row row-cols-1 no-gutters">
              {value &&
                value.article
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((single, key) => {
                    return (
                      <div key={key} className="col mb-6" data-aos="fade-up">
                        <BlogClassic data={single} key={key} />
                      </div>
                    );
                  })}
              ;
            </div>

            <div className="row mt-10">
              <div className="col pagination center">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={value?.article.length}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
          <div className={`col-lg-4 col-12 order-lg-2 mb-10`}>
            <SidebarWrap>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Төрөлүүд"
                />
                <SidebarCategories />
                <SidebarCategoriesNews />
                <SidebarCategoriesActivity />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Бусад нийтлэлүүд"
                />
                <SidebarPost />
              </SidebarWidget>
            </SidebarWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogClassicContainer.propTypes = {
  data: PropTypes.object,
};

export default BlogClassicContainer;
