import React, { useState } from "react";
import PropTypes from "prop-types";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
// import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import SidebarCategoriesNews from "../../components/Sidebar/SidebarCategoriesNews.jsx";
// import SidebarCategoriesActivity from "../../components/Sidebar/SidebarCategoriesActivity";
import SidebarPostActivity from "../../components/Sidebar/SidebarPostActivity";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData.js";

const WorkDetailsContainer = ({ data, listData }) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="section section-padding">
      <div className="container d-block d-md-flex  ">
        <div className="container border col-12 col-md-9 col-lg-8  mb-10">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              Ажлын зарууд
            </button>

            <button
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              Бидний тухай
            </button>
          </div>

          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? "content  active-content" : "content"
              }
            >
              <div className="desc">
                <div className="company-image" data-aos="fade-up">
                  <img
                    className="mx-auto d-block"
                    src={
                      process.env.PUBLIC_URL +
                      `${getData(
                        listData[0] && listData[0]._embedded,
                        "image"
                      )}`
                    }
                    alt="Company Image"
                  />
                </div>
                <div className="info">
                  <h3 className="title my-5 text-center">
                    {data && data.title.rendered}
                  </h3>
                  <div className=" p-5">
                    {listData &&
                      listData.map((value, key) => {
                        return (
                          <div className="border p-5 d-md-flex" key={key}>
                            <div className="col-4 col-md-3 col-lg-2 mr-3">
                              {value && value.acf.date}
                            </div>
                            <div className="">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/work-info/${value.acf.companyname}/${value.id}`
                                }
                              >
                                <div className="text-primary h5">
                                  {value && value.title.rendered}
                                </div>
                              </Link>
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: listData && listData.excerpt.rendered,
                                }}
                              /> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 2 ? "content  active-content" : "content"
              }
            >
              <div className="company-image " data-aos="fade-up">
                <img
                  className="mx-auto d-block"
                  src={
                    process.env.PUBLIC_URL +
                    `${getData(listData[0] && listData[0]._embedded, "image")}`
                  }
                  alt="Company Image"
                />
              </div>
              <h3 className="title my-5 text-center">Бидний тухай</h3>
              <hr />{" "}
              <div className="info">
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: data && data.content.rendered,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-4 order-lg-1 mb-10 ml-10">
          <SidebarWrap>
            <SidebarWidget>
              <SidebarTitle classOption="mb-2 text-primary" title="Төрөлүүд" />
              {/* <SidebarCategories /> */}
              <SidebarCategoriesNews />
              {/* <SidebarCategoriesActivity /> */}
            </SidebarWidget>
            <SidebarWidget>
              <SidebarTitle
                classOption="mb-2 text-primary"
                title="Бусад арга хэмжээ"
              />
              <SidebarPostActivity />
            </SidebarWidget>
          </SidebarWrap>
        </div>
      </div>
    </div>
  );
};

WorkDetailsContainer.propTypes = {
  data: PropTypes.object,
  listData: PropTypes.array,
};

export default WorkDetailsContainer;
