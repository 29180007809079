import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const WorkCompanyList = ({ data, classOption }) => {
  return (
    <div
      className={` work-box border border-light shadow d-flex  ${classOption}`}
    >
      <div className="content row d-flex align-items-center  ">
        <Link
          to={process.env.PUBLIC_URL + `/work-details/${data.acf.companyname}`}
          className="image col-4 col-md-6 col-lg-6 col-xl-5"
        >
          <img
            className="rounded shadow"
            src={
              process.env.PUBLIC_URL +
              `${getData(data && data._embedded, "image")}`
            }
            alt="News Image"
          />
        </Link>
        <div className="text col-8 col-md-6 col-lg-6 col-xl-7">
          <Link
            to={
              process.env.PUBLIC_URL + `/work-details/${data.acf.companyname}`
            }
            className="title"
          >
            <h3 className="title  text-primary">
              {data && data.title.rendered}
            </h3>
          </Link>
          <div className="info"></div>
        </div>
      </div>
    </div>
  );
};

WorkCompanyList.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

WorkCompanyList.defaultProps = {
  classOption: "work-box d-flex  ",
};

export default WorkCompanyList;
