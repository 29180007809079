import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import WorkPage from "../container/Work/WorkPage";

const Work = () => {
  return (
    <React.Fragment>
      <SEO title="Бид чадна || Ажлын байр" />
      <Header />
      <Breadcrumb
        image="images/bg/news.jpeg"
        title="Ажлын байр"
        content="Нүүр хуудас"
        contentTwo="Ажлын байр"
        url="work"
      />
      <WorkPage />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Work;
