import PropTypes from "prop-types";
import { useEffect, useRef, useState, useContext } from "react";
import IconBox from "../../components/IconBox/IconBox.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from "parallax-js";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import SidebarCategoriesNews from "../../components/Sidebar/SidebarCategoriesNews.jsx";
import SidebarCategoriesActivity from "../../components/Sidebar/SidebarCategoriesActivity";
import SidebarPostActivity from "../../components/Sidebar/SidebarPostActivity";
import Pagination from "../../components/pagination/Pagination.jsx";
import { DataContext } from "../../context";

const ActivityIconBox = ({ classOption }) => {
  const sceneEl = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState([6]);

  const value = useContext(DataContext);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(value.activity);

  return (
    <div
      className={`section section-padding-t90 section-padding-bottom ${classOption}`}
    >
      <div className="container">
        <SectionTitle
          headingOption="fz-32"
          title="Хөжлийн бэрхшээлтэй иргэдэд чиглэсэн олон сайн дурын аян үйл ажилгаанууд зохогддог"
        />
        <div className="row mb-n10">
          <div className="col-lg-8 col-xl-8 col-12 order-lg-1 mb-10">
            <div className="row row-cols-1 mb-n6 icon-box-shape-animation no-gutters">
              {value &&
                value.activity
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((single) => {
                    return (
                      <div
                        key={single.id}
                        className="col mb-6"
                        data-aos="fade-up"
                      >
                        <IconBox data={single} />
                      </div>
                    );
                  })}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-12 order-lg-1 mb-10">
            <SidebarWrap>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Төрөлүүд"
                />
                <SidebarCategories />
                <SidebarCategoriesNews />
                <SidebarCategoriesActivity />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle
                  classOption="mb-2 text-primary"
                  title="Бусад арга хэмжээ"
                />
                <SidebarPostActivity />
              </SidebarWidget>
            </SidebarWrap>
          </div>
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={value.activity.length}
          paginate={paginate}
        />
        <div className="shape shape-1" id="scene" ref={sceneEl}>
          <span data-depth="1">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/shape-animation/video-shape-1.png"
              }
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ActivityIconBox.propTypes = {
  classOption: PropTypes.string,
};
ActivityIconBox.defaultProps = {
  classOption: "section section-padding-t90 section-padding-bottom",
};

export default ActivityIconBox;
