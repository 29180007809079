import React, { useState, useEffect } from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import NewsDetailsContainer from "../container/NewsPage/NewsDetailsContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import WPAPI from "wpapi";
import axios from "axios";
import Config from "../config";

const wp = new WPAPI({ endpoint: Config.apiUrl });
const NewsDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [newsDetails, setNewsDetails] = useState([]);

  useEffect(() => {
    const newsFeed = wp.posts().categories(2).perPage(100).embed();
    axios.get(newsFeed).then((res) => {
      setNewsDetails(res.data);
    });
    console.log(newsFeed);
  }, []);

  console.log(newsDetails);
  const newsId = parseInt(id, 10);
  const data = newsDetails.filter((news) => news.id === newsId);

  return (
    <React.Fragment>
      <SEO title="Бид чадна || Мэдээ мэдээлэл" />
      <Header />
      <Breadcrumb
        image="images/bg/bg-1.jpeg"
        title={data[0]?.title.rendered}
        content="Нүүр хуудас"
        contentTwo="Мэдээ мэдээлэл"
        url={"news"}
      />
      <NewsDetailsContainer data={data[0]} />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

NewsDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default NewsDetails;
