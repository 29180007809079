import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import GoogleMap from "../container/Map/GoogleMap";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Contact = () => {
  return (
    <React.Fragment>
      <SEO title="Бид чадна || Холбоо барих" />
      <Header />
      <Breadcrumb
        image="images/hero-image/about.jpeg"
        title="Холбоо барих"
        content="Нүүр хуудас"
        contentTwo="Холбоо барих"
        url="contact"
      />
      <ContactInformation />
      <GoogleMap />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Contact;
